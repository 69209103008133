import { eachDayOfInterval, format as dateFnsFormat, isSameDay, lastDayOfWeek, parseISO, startOfWeek } from 'date-fns';
import { lt as locale } from 'date-fns/locale';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import tlkIcon from '../../assets/icons/tlk.svg';
import { api } from '../../services/api-service';
import Loader from '../Loader/Loader';
import { Money } from './Money';
import Styled from './Registration.styles';
const defaultProps = {
    showPrice: true,
    showImage: true,
    target: '_blank'
};
const Registration = (props) => {
    var _a;
    const { link, showPrice, showImage, target } = Object.assign(Object.assign({}, defaultProps), props);
    const [workplaceId, SetWorkplaceId] = useState();
    const [serviceId, SetServiceId] = useState();
    const [workplaceServices, setWorkplaceServices] = useState();
    const [timeSlots, setTimeSlots] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [selected, setSelected] = useState();
    const [selectedTimeSlot, setSelectedTimeSlot] = useState();
    const [serviceImage, setServiceImage] = useState();
    const [hasContent, setHasContent] = useState(false);
    const [loading, setLoading] = useState(true);
    const [weekStart, setWeekStart] = useState(new Date());
    const [endOfWeek, setEndOfWeek] = useState(new Date());
    const [weekDays, setWeekDays] = useState();
    const [refresh, setRefresh] = useState(false);
    const [showTakenTimeSlotModal, setShowTakenTimeSlotModal] = useState(false);
    const weekOptions = { weekStartsOn: 1 };
    const format = (date, formatStr = 'PP') => dateFnsFormat(date, formatStr, { locale });
    useEffect(() => {
        const onLoad = () => {
            if (link) {
                var url = new URL(link);
                const splitted = url.pathname.split('/');
                if (splitted[2] && splitted[3]) {
                    SetWorkplaceId(Number(splitted[2]));
                    SetServiceId(Number(splitted[3]));
                }
            }
            else {
                SetWorkplaceId(props.workplaceId);
                SetServiceId(props.serviceId);
            }
        };
        onLoad();
    }, []);
    useEffect(() => {
        if (refresh) {
            const loadAll = async () => {
                let ts = null;
                const ws = await api.getWorkplaceServices(workplaceId, serviceId);
                setWorkplaceServices(ws);
                if (ws && ws.upcomingVisit) {
                    const dateFrom = moment(ws.upcomingVisit).isoWeekday(1).format('YYYY-MM-DD');
                    const dateTo = moment(ws.upcomingVisit).isoWeekday(7).format('YYYY-MM-DD');
                    setWeekStart(startOfWeek(new Date(dateFrom), weekOptions));
                    setEndOfWeek(lastDayOfWeek(new Date(dateFrom), weekOptions));
                    setWeekDays(eachDayOfInterval({ start: new Date(dateFrom), end: new Date(dateTo) }));
                    ts = await api.getTimeSlots(workplaceId, serviceId, dateFrom, dateTo);
                    setTimeSlots(ts);
                    const img = api.getSpecialistImage(ws);
                    setServiceImage(img);
                }
                setHasContent(ws !== null && ts != null);
                setLoading(false);
            };
            if (workplaceId && serviceId) {
                loadAll();
            }
            setRefresh(false);
        }
    }, [refresh]);
    useEffect(() => {
        const loadAll = async () => {
            let ts = null;
            const ws = await api.getWorkplaceServices(workplaceId, serviceId);
            setWorkplaceServices(ws);
            console.log('ws', ws);
            if (ws && ws.upcomingVisit) {
                const dateFrom = moment(ws.upcomingVisit).isoWeekday(1).format('YYYY-MM-DD');
                const dateTo = moment(ws.upcomingVisit).isoWeekday(7).format('YYYY-MM-DD');
                setWeekStart(startOfWeek(new Date(dateFrom), weekOptions));
                setEndOfWeek(lastDayOfWeek(new Date(dateFrom), weekOptions));
                setWeekDays(eachDayOfInterval({ start: new Date(dateFrom), end: new Date(dateTo) }));
                ts = await api.getTimeSlots(workplaceId, serviceId, dateFrom, dateTo);
                setTimeSlots(ts);
                const img = api.getSpecialistImage(ws);
                setServiceImage(img);
            }
            setHasContent(ws !== null && ts != null);
            setLoading(false);
        };
        if (workplaceId && serviceId) {
            loadAll();
        }
    }, [workplaceId, serviceId]);
    const getTimeSlots = useCallback(() => {
        var slots = [];
        timeSlots && weekDays.forEach((weekDay) => {
            if (Array.isArray(timeSlots)) {
                let daySlots = timeSlots.filter((time) => isSameDay(parseISO(time.startDate), weekDay) &&
                    (time.statusName === 'Available' ||
                        (time.duplicatedTimeSlotId !== null
                            ? selected === time.duplicatedTimeSlotId
                            : selected === time.timeSlotId)));
                const filteredSlots = daySlots.filter((ts) => ts.statusName === 'Available' &&
                    ts.isReserved === false &&
                    ts.visitStatus === null);
                if (showMore === true) {
                    daySlots = filteredSlots;
                }
                else {
                    daySlots = filteredSlots.slice(0, 3);
                }
                // Exclude extra time slots for whom price is not set yet.
                daySlots = daySlots.filter((ts) => !(ts.tags.includes(process.env.REACT_APP_EXTRA_TAG) && !workplaceServices.extraPrice));
                slots.push(daySlots);
            }
        });
        const maxLenght = slots.reduce((r, s) => (r.length > s.length ? r : s), 0).length;
        const extendedSlots = slots.map((slot) => [
            ...slot,
            ...new Array(maxLenght - slot.length).fill(0),
        ]);
        console.log('>>>', extendedSlots);
        return extendedSlots;
    }, [selected, timeSlots, weekDays, showMore]);
    const timeSlotTime = (timeSlotDate) => {
        var moment = require('moment-timezone');
        const convertedTime = moment(timeSlotDate)
            .tz('Europe/Vilnius')
            .format('HH:mm');
        return convertedTime;
    };
    const doReservation = async () => {
        if (selectedTimeSlot) {
            var timeSlotStatus = await api.getTimeSlot(selectedTimeSlot.timeSlotId, serviceId, selectedTimeSlot.isPaid);
            if (timeSlotStatus.isReserved === false && timeSlotStatus.statusId === 1) {
                setShowTakenTimeSlotModal(false);
                console.log('selectedTimeSlot.isPaid', selectedTimeSlot.isPaid);
                window.open(`${process.env.REACT_APP_BASE_ADDRESS}/order/${workplaceId}/${serviceId}/${selectedTimeSlot.timeSlotId}/${selectedTimeSlot.isPaid}`, target);
            }
            else {
                setRefresh(true);
                setShowTakenTimeSlotModal(true);
            }
        }
    };
    const showMoreTimeSlots = () => {
        window.open(`${process.env.REACT_APP_BASE_ADDRESS}/order/${workplaceId}/${serviceId}`, target);
    };
    const showMoreVisits = () => {
        window.open(`${process.env.REACT_APP_BASE_ADDRESS}`, target);
    };
    const onTimeSlotSelect = (timeSlot) => {
        const { timeSlotId, duplicatedTimeSlotId } = timeSlot;
        setSelectedTimeSlot(timeSlot);
        setSelected(duplicatedTimeSlotId === null
            ? timeSlotId
            : duplicatedTimeSlotId);
    };
    return (React.createElement(React.Fragment, null,
        loading && (React.createElement("div", { className: 'container-fluid' },
            React.createElement("div", { className: "d-flex justify-content-center" },
                React.createElement(Loader, null)))),
        !loading && hasContent && (React.createElement(Styled.Registration, { className: "container-fluid" },
            React.createElement(Styled.Description, { className: "row" },
                showImage && (React.createElement(Styled.Image, { className: "col-md-6 col-lg-4", imgUrl: serviceImage })),
                React.createElement(Styled.Content, { className: `${showImage ? "col-md-6 col-lg-8" : "col"}` },
                    React.createElement("div", { className: "d-flex flex-column" },
                        React.createElement(Styled.Title, { className: "p-2" }, !workplaceServices.specialistId ? workplaceServices === null || workplaceServices === void 0 ? void 0 : workplaceServices.serviceTitle : (workplaceServices === null || workplaceServices === void 0 ? void 0 : workplaceServices.specialistName) + ' - ' + (workplaceServices === null || workplaceServices === void 0 ? void 0 : workplaceServices.serviceTitle)),
                        React.createElement(Styled.Badge, { className: "p-2" }, workplaceServices === null || workplaceServices === void 0 ? void 0 : workplaceServices.serviceTypeTitle),
                        showPrice && (React.createElement(Styled.Price, { className: "p-2" },
                            "Kaina:",
                            ' ',
                            React.createElement("span", null,
                                ' ', (_a = workplaceServices === null || workplaceServices === void 0 ? void 0 : workplaceServices.priceWithDiscount) === null || _a === void 0 ? void 0 :
                                _a.toFixed(2),
                                "\u20AC"))),
                        React.createElement(Styled.TimeSlotLegend, { className: "p-2 pb-0 row row-cols-auto row-cols-md-1" },
                            React.createElement(Styled.TimeSlotInfo, { className: 'pb-1 col' },
                                React.createElement(Styled.TimeSlot, null,
                                    React.createElement(Styled.Slot, { legend: true })),
                                React.createElement(Styled.LegendTitle, null, "Mokami laikai")),
                            (workplaceServices === null || workplaceServices === void 0 ? void 0 : workplaceServices.extraPrice) !== 0 && (React.createElement(Styled.TimeSlotInfo, { className: 'pb-1 col' },
                                React.createElement(Styled.TimeSlot, null,
                                    React.createElement(Styled.Slot, { isExtra: true, legend: true })),
                                React.createElement(Styled.LegendTitle, null, "Papildomi mokami laikai"))),
                            React.createElement(Styled.TimeSlotInfo, { className: 'col' },
                                React.createElement(Styled.TimeSlot, null,
                                    React.createElement(Styled.Slot, { isPaid: true, legend: true },
                                        React.createElement(Styled.TlkIconSvg, null,
                                            React.createElement("img", { src: tlkIcon, alt: "tlk" })))),
                                React.createElement(Styled.LegendTitle, { isPaid: true }, "Kompensuoja PSDF")))))),
            React.createElement("div", { className: "row" },
                React.createElement(Styled.DateRange, { className: "col" }, `${format(weekStart, 'yyyy MMMM d')}-${format(endOfWeek, 'd')} d.`)),
            React.createElement("div", { className: "row" },
                React.createElement(Styled.Calendar, { className: 'col' },
                    React.createElement(Styled.CalendarHeader, null, weekDays.map((day, i) => (React.createElement(Styled.CalendarDay, { key: i },
                        format(day, 'd'),
                        React.createElement("small", null, format(day, 'ccc')))))),
                    showTakenTimeSlotModal && React.createElement(Styled.TakenTime, null, "Pasirinktas laikas jau u\u017Eimtas. Rinkit\u0117s kit\u0105 laik\u0105."),
                    React.createElement(Styled.CalendarColumns, null, getTimeSlots().map((column, i) => (React.createElement(Styled.CalendarColumn, { key: i }, column.map((item, ii) => (React.createElement(Styled.CalendarCell, { key: ii }, item ? (React.createElement(Styled.Slot, { isPaid: !item.isPaid, isExtra: item.isExtra, isTaken: item.isReserved === 1 ||
                            item.visitStatus !== null, price: showPrice &&
                            (item.isExtra || item.isPaid), selected: item.duplicatedTimeSlotId === null
                            ? selected === item.timeSlotId
                            : selected === item.duplicatedTimeSlotId, onClick: () => {
                            if (item.isReserved !== true &&
                                item.visitStatus === null) {
                                onTimeSlotSelect(item);
                            }
                        } },
                        !item.isPaid && !item.isExtra && (React.createElement(Styled.TlkIconSvg, null,
                            React.createElement("img", { src: tlkIcon, alt: "tlk" }))),
                        showPrice &&
                            (item.isExtra || item.isPaid) && (React.createElement(Styled.ExtraPrice, { extra: item.isExtra },
                            React.createElement(Money, { amount: item.isExtra ? workplaceServices.extraPrice : workplaceServices.priceWithDiscount }))),
                        timeSlotTime(item.startDate))) : (React.createElement(Styled.CalendarSlotSpacer, null))))))))),
                    (timeSlots === null || timeSlots === void 0 ? void 0 : timeSlots.length) > 0 && (React.createElement("div", { className: 'd-flex justify-content-center' },
                        React.createElement(Styled.CalendarShowMore, { className: 'btn btn-outline-secondary btn-sm text-center text-dark w-100', onClick: () => setShowMore(showMore => !showMore) }, !showMore ? `Rodyti daugiau` : 'Rodyti mažiau'))))),
            React.createElement("div", { className: 'row text-center' },
                React.createElement("div", { className: 'col pb-4' },
                    (timeSlots === null || timeSlots === void 0 ? void 0 : timeSlots.length) > 0 && (React.createElement("button", { className: 'btn btn-primary text-white', onClick: () => doReservation() }, "Registruotis")),
                    React.createElement("button", { className: 'btn btn-outline-dark bg-white text-dark', style: { marginLeft: '1rem' }, onClick: () => showMoreTimeSlots() }, "Daugiau laik\u0173"))))),
        !loading && !hasContent && (React.createElement("div", { className: 'container-fluid text-center' },
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col p-4' },
                    React.createElement("span", null, "Registracija vizitui nerasta. Bandykite ie\u0161koti kito vizito."))),
            React.createElement("div", { className: 'row' },
                React.createElement("div", { className: 'col' },
                    React.createElement("button", { className: 'btn btn-primary text-white', style: { marginLeft: '1rem' }, onClick: () => showMoreVisits() }, "Rinktis kit\u0105 vizit\u0105")))))));
};
export { Registration };
