var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { mediaDown } from '../../utils/styles/media';
const Registration = styled.div `
  max-width: 100%;
  background-color: #f1f5f7;
`;
const Description = styled.div ``;
const Image = styled.div `
  border-radius: 0 0px 15px 0;
  ${mediaDown('md') `
    height: 250px;
    border-radius: 0;
  `}
  background-position: center 25%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${(props) => props.imgUrl};
`;
const Content = styled.div ``;
const WorkplaceServices = styled.ul `
  margin-bottom: 12px;
`;
const Title = styled.div `
  font-size: 24px;
  font-weight: 900;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const Badge = styled.div `
  background-color: #6a32e6;
  color: #ffffff;
  font-size: 11px;
  font-weight: 800;
  line-height: 14px;
  padding: 5px 7px;
  border-radius: 6px;
  width: fit-content;
  margin-left: 8px;
`;
const Price = styled.div `
  font-size: 16px;
  span {
    font-size: 24px;
    font-weight: 900;
  }
`;
const TimeSlotLegend = styled.div `
  margin-top: 15px;
  ${mediaDown('md') `
    margin-top: 0;
  `}
`;
const TimeSlotInfo = styled.div `
  display: flex;
`;
const TimeSlot = styled.div `
  width: 40px;
`;
const Slot = styled.div `
  border-radius: 5px;
  margin: 1px 0 1px 0;
  height: 36px;
  background-color: #CCF9E5;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${(props) => (props.price ? 'column-reverse' : '0')};
  color: ${(props) => props.selected
    ? '#000'
    : props.isTaken
        ? '#c7c7c7'
        : props.isExtra
            ? '#ffffff'
            : props.isPaid
                ? '#519c77'
                : '#3655d7'};
  background-color: ${(props) => props.isTaken ? '#f5f9fa' : props.isExtra ? '#7447E6' : (props.isPaid ? '#CCF9E5' : '#a2cbff')};
  font-size: 13px;
  cursor: ${(props) => props.legend ? 'default' : props.isTaken ? 'default' : 'pointer'};

  font-weight: ${(props) => (props.selected ? 'bold' : '500')};
  border: 2px solid
    ${(props) => props.selected
    ? '#000'
    : props.isTaken
        ? '#f5f9fa'
        : props.isExtra
            ? '#7447E6'
            : props.isPaid
                ? '#CCF9E5'
                : '#a2cbff'};
  transition: opacity 0.15s;
  position: relative;
  overflow: hidden;
  line-height: 1.2rem !important;
  ${mediaDown('md') `
    min-width: 35px;
    font-size: 12px;
  `}

   ${mediaDown('lg') `
    min-width: 35px;
  `}
`;
const LegendTitle = styled.div `
  margin-left: 5px;
  align-self: center;
  color: ${(props) => (props.isExtra ? '#7447E6' : (props.isPaid ? '#519c77' : 'black'))};
`;
const DateRange = styled.div `
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  padding: 1rem;
`;
const TlkIconSvg = styled.div `
  position: absolute;
  bottom: 0;
  right: -8px;
  height: 29px;
  z-index: 0;
  img {
    ${mediaDown('xl') `
    display: none;
  `}
    height: 35px;
    width: 35px;
    fill: rgba(0, 0, 0, 0.1);
    -webkit-filter: grayscale(1) invert(0.8);
    filter: grayscale(1) invert(0.8);
  }
`;
const Calendar = styled.div `
  border-radius: 6px;
  background-color: #fff;
  margin: 0 20px 20px;
  padding-bottom: 20px;
  user-select: none;
`;
const CalendarHeader = styled.div `
  position: relative;
  display: grid;
  grid-gap: 10px;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  flex-grow: 1;
  padding: 27px 0 15px;
  border-bottom: 1px solid #eff5f7;

  ${mediaDown('md') `
    padding: 17px 0 10px;
    border-bottom: none;
  `}
`;
const CalendarDay = styled.div `
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;

  ${mediaDown('md') `
    font-size: 16px;
    line-height: 20px;
  `}

  small {
    color: #9395a9;
    font-size: 12px;
    line-height: 12px;
    display: block;
    margin-top: 4px;
    text-transform: capitalize;

    ${mediaDown('md') `
      font-size: 9px;
      line-height: 12px;
    `}
  }
`;
const CalendarColumns = styled.div `
  display: grid;
  grid-gap: 0;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  margin-top: -1px;

  ${mediaDown('md') `
    padding: 2.5px;
    margin-top: 0;
  `}
`;
const CalendarColumn = styled.div `
  border-right: 1px solid #eff5f7;

  ${mediaDown('md') `
    border: none;
  `}
`;
const CalendarCell = styled.div `
  padding: 2px;
  border-top: 1px solid #eff5f7;

  ${mediaDown('md') `
    padding: 2.5px;
    border: none;
  `}
`;
const CalendarSlotSpacer = styled.div `
  background-color: #f5f9fa;
  border-radius: 5px;
  height: 40px;
`;
const Buttons = styled.div `
`;
const Button = styled.div `
`;
const CalendarShowMore = styled.div `
`;
const Modal = styled.div `
  background-color: red;
  position: absolute;
  height: 75px;
  width: 200px;
  top: 25%;
  left: 25%;
  display:flex;
  justify-content:center;
  align-items:center;
  border-radius: 15px;

 
  
`;
const Cross = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject([void 0], ["\ndisplay: inline-block;\n  content: \"\\00d7\";\n\n"])));
const Backdrop = styled.div `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
`;
const TakenTime = styled.div `
  display:flex;
  justify-content: center;
  width: 100%;
  border-radius: 15px;
  background-color: red;
  color: white;
`;
const ExtraPrice = styled.div `
  color: ${(props) => props.extra ? 'rgba(255, 255, 255, 0.9)' : 'rgba(128,128,128)'};
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-left: 2px;
   ${mediaDown('md') `
    font-size: 8px;
  `}

  ${mediaDown('lg') `
   font-size: 8px;
  `}
`;
const Styled = {
    Registration,
    Description,
    Image,
    Content,
    WorkplaceServices,
    Title,
    Badge,
    Price,
    TimeSlotLegend,
    TimeSlotInfo,
    TimeSlot,
    Slot,
    LegendTitle,
    DateRange,
    TlkIconSvg,
    Calendar,
    CalendarHeader,
    CalendarDay,
    CalendarColumns,
    CalendarColumn,
    CalendarCell,
    CalendarSlotSpacer,
    Buttons,
    Button,
    CalendarShowMore,
    Modal,
    Backdrop,
    Cross,
    TakenTime,
    ExtraPrice
};
export default Styled;
var templateObject_1;
