// https://getbootstrap.com/docs/5.0/layout/breakpoints/
export const breakpoints = {
    sm: 575.98,
    md: 767.98,
    lg: 991.98,
    xl: 1199.98,
    xxl: 1399.98,
};
export const mediaDown = (key) => {
    return (style) => `@media (max-width: ${breakpoints[key]}px) { ${style} }`;
};
export const mediaUp = (key) => {
    return (style) => `@media (min-width: ${breakpoints[key] + 1}px) { ${style} }`;
};
