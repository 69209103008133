import React, { useState } from 'react';
import Styled from './PricesAccordion.styles';
import { api } from '../../services/api-service';
const PricesAccordion = ({ isDepartment, title, content, selectedDepartmentId }) => {
    const [isActive, setIsActive] = useState(() => {
        const active = [];
        content.map((d, i) => {
            active.push({ isActive: false });
        });
        return active;
    });
    function changeActiveState(idx) {
        setIsActive((state) => [...state, (state[idx].isActive = !state[idx].isActive)]);
    }
    async function redirectToServices(serviceId) {
        const response = await api.getServiceParameters(selectedDepartmentId, serviceId);
        if (response.categoryId != 0 && response.serviceTypeId != 0) {
            window.open(`${process.env.REACT_APP_BASE_ADDRESS}/services/${response.categoryId}/${response.serviceTypeId}?departmentId=${response.searchDepartmentId}&serviceId=${response.searchServiceId}`, '_blank');
        }
    }
    return (React.createElement(React.Fragment, null,
        isDepartment != '' && React.createElement("h3", null,
            title,
            " kain\u0173 s\u0105ra\u0161as:"),
        isDepartment == '' && React.createElement("h3", null, title),
        content.map(({ groupName, services }, idx) => {
            return (React.createElement(React.Fragment, null,
                React.createElement(Styled.PricesAccordionTitle, { onClick: () => changeActiveState(idx) },
                    React.createElement("div", null, groupName),
                    React.createElement("div", null, isActive[idx].isActive ? '-' : '+')),
                isActive[idx].isActive && (React.createElement(Styled.PricesAccordionContent, null,
                    React.createElement("table", null,
                        React.createElement("tbody", null, services.map(({ externalServiceId, serviceName, price }) => {
                            return (React.createElement("tr", { onClick: () => redirectToServices(externalServiceId), style: { cursor: 'pointer' } },
                                React.createElement("td", null, serviceName),
                                React.createElement("td", null, price)));
                        })))))));
        })));
};
export { PricesAccordion };
