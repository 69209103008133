import styles from '!!raw-loader!sass-loader!../styles/style.scss';
import React from 'react';
import * as ReactDOM from 'react-dom';
import root from 'react-shadow/styled-components';
function parseValue(value) {
    if (value === 'true') {
        return true;
    }
    if (value === 'false') {
        return false;
    }
    if (Number(value).toString() === value) {
        return Number(value);
    }
    return value;
}
function parseKey(key) {
    const parts = key.split('-');
    const newParts = [parts[0]];
    for (let i = 1; i < parts.length; i++) {
        const firstLetter = parts[i].slice(0, 1);
        const restOfLetters = parts[i].slice(1);
        const newPart = firstLetter.toUpperCase() + restOfLetters;
        newParts.push(newPart);
    }
    return newParts.join('');
}
function attrToObj(attrs) {
    const attrsObj = {};
    const length = attrs.length;
    for (let i = 0; i < length; i++) {
        const { name, value } = attrs[i];
        attrsObj[parseKey(name)] = parseValue(value);
    }
    return attrsObj;
}
function HtmlTagWrapper(Component, className, widgetName) {
    const els = Array.from(document.getElementsByClassName(className));
    for (const el of els) {
        const attrs = el.attributes;
        const props = attrToObj(attrs);
        if (props['widget'] === widgetName) {
            // console.log(props);
            const style = `${styles}`;
            ReactDOM.render(React.createElement(root.div, null,
                React.createElement(Component, Object.assign({}, props)),
                React.createElement("style", { type: "text/css" }, style)), el);
        }
    }
}
export { HtmlTagWrapper };
