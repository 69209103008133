import styled from 'styled-components';
const PricesAccordionTitle = styled.div `
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #0087f7;
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding: 1rem;
  border-radius: 15px;
  &:hover {
    filter: brightness(95%);
  }
  margin-bottom: 15px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 4px, rgba(0, 0, 0, 0.1) 0px 5px 10px;
`;
const PricesAccordionContent = styled.div `
  background-color: #fff;
  padding: 0.5rem;

  table {
    width: 100%;
    text-align: left;
  }

  thead {
    font-weight: normal;
    background-color: #0087f7;
    font-size: 16px;
    color: #fff;
  }

  th {
    padding: 0.5rem;
  }

  th:nth-child(2) {
    position: relative;
    float: right;
    margin-right: 25px;
  }

  tr:nth-child(even) {
    background-color: rgb(241, 245, 247);
  }

  td {
    padding: 0.5rem;
  }

  td:nth-child(2) {
    position: relative;
    float: right;
    margin-right: 25px;
  }
`;
const Styled = {
    PricesAccordionTitle,
    PricesAccordionContent,
};
export default Styled;
