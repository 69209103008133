import axios from 'axios';
const http = axios.create({
    baseURL: process.env.REACT_APP_API_BASE,
    headers: {
        'Content-type': 'application/json',
    },
});
http.interceptors.request.use(async function (config) {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE}/Login/clientToken/${process.env.REACT_APP_CLIENT_ID}`);
    const token = response === null || response === void 0 ? void 0 : response.data;
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});
http.interceptors.response.use(null, (error) => {
    var _a;
    const { response } = error;
    if (!response) {
        // Network error.
        console.error(error);
        return;
    }
    const errorMessage = ((_a = response.data) === null || _a === void 0 ? void 0 : _a.message) || response.statusText;
    console.error('ERROR:', errorMessage);
});
export default http;
