import 'babel-polyfill';
import { Prices } from './components/Prices/Prices';
import { Registration } from './components/Registration/Registration';
import './styles/style.scss';
import { HtmlTagWrapper } from './utils/html-tag-wrapper';
const className = 'affidea-widget';
const WrappedPrices = HtmlTagWrapper(Prices, className, 'prices');
const WrappedRegistration = HtmlTagWrapper(Registration, className, 'registration');
export default {
    Prices: WrappedPrices,
    Registration: WrappedRegistration,
};
