import React, { useEffect, useState } from 'react';
import { api } from '../../services/api-service';
import Styled from './Prices.styles';
import { PricesAccordion } from './PricesAccordion';
import Loader from '../Loader/Loader';
const Prices = (props) => {
    const { departmentId, groupId, firstLevelHierarchyId, showZeroPrice } = Object.assign({}, props);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const onLoad = async () => {
            let prices = null;
            prices = await api.getPrices(departmentId, firstLevelHierarchyId, groupId, showZeroPrice);
            setData(prices);
            setLoading(false);
            console.log();
        };
        onLoad();
    }, []);
    return (React.createElement(React.Fragment, null,
        loading && (React.createElement("div", { className: "container-fluid" },
            React.createElement("div", { className: "d-flex justify-content-center" },
                React.createElement(Loader, null)))),
        !loading && data && (React.createElement(Styled.PricesAccordion, null, data.map(({ externalDepartmentId, departmentName, departmentGroups }) => (React.createElement(PricesAccordion, { isDepartment: departmentId, title: departmentName, content: departmentGroups, selectedDepartmentId: externalDepartmentId })))))));
};
export { Prices };
