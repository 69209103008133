import cat1Img from '../assets/images/cat-1.png';
import cat2Img from '../assets/images/cat-2.png';
import cat3Img from '../assets/images/cat-3.png';
import defaultCategoryImage from '../assets/images/cat-default.png';
import defaultSpecialistImage from '../assets/images/specialist-default.png';
import http from '../utils/http';
export const api = {
    getWorkplaceServices,
    getTimeSlots,
    getPrices,
    getSpecialistImage,
    getServiceParameters,
    getTimeSlot,
};
async function getWorkplaceServices(workplaceId, serviceId) {
    const response = await http.get(`/Services/WorkplaceServices/${workplaceId}/${serviceId}`);
    // console.log('response', response);
    if (response.status === 204) {
        return null;
    }
    return response === null || response === void 0 ? void 0 : response.data;
}
async function getTimeSlots(workplaceId, serviceId, dateFrom, dateTo) {
    const response = await http.get(`/TimeSlots?WorkplaceId=${workplaceId}&DateFrom=${dateFrom}&DateTo=${dateTo}&Filter=all&serviceId=${serviceId}`);
    return response === null || response === void 0 ? void 0 : response.data;
}
async function getTimeSlot(timeSlotId, serviceId, isPaid) {
    const response = await http.get(`/TimeSlots/${timeSlotId}/${serviceId}/${isPaid}`);
    return response === null || response === void 0 ? void 0 : response.data;
}
async function getPrices(departmentId, firstLevelHierarchyId, groupId, showZeroPrice) {
    const response = await http.get(`/Services/servicePrices`, {
        params: {
            departmentId: departmentId,
            firstLevelHierarchyId: firstLevelHierarchyId,
            groupId: groupId,
            showZeroPrice: showZeroPrice,
        },
    });
    return response === null || response === void 0 ? void 0 : response.data;
}
async function getServiceParameters(departmentId, serviceId) {
    const response = await http.get(`/Services/ServicePriceServiceParameters`, {
        params: {
            departmentId: departmentId,
            serviceId: serviceId,
        },
    });
    return response === null || response === void 0 ? void 0 : response.data;
}
function getSpecialistImage(service) {
    const categories = {
        1: cat1Img,
        2: cat2Img,
        3: cat3Img,
    };
    const url = service.specialistId
        ? `${process.env.REACT_APP_API_BASE}/Specialists/${service.specialistId}/picture`
        : categories[service.categoryId] || defaultCategoryImage;
    return `url(${url}), url(${defaultSpecialistImage})`;
}
